const individuals = [
  "batouresearch",
  "meta",
  "laion-ai",
  "vaibhavs10",
  "prompthero",
  "stability-ai",
  "google-research",
  "jbilcke",
  "microsoft",
  "tomasmcm",
  "pollinations",
  "pixray",
  "tstramer",
  "mcai",
  "pagebrain",
  "lucataco",
  "zylim0702",
];

// split into two groups
const [top, bottom] = individuals.reduce(
  (acc, curr, index) => {
    if (index % 2 === 0) {
      acc[0].push(curr);
    } else {
      acc[1].push(curr);
    }
    return acc;
  },
  [[], []] as string[][],
);

import {
  autoUpdate,
  flip,
  offset,
  shift,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  FloatingArrow,
  arrow,
} from "@floating-ui/react";
import { forwardRef, useRef, useState } from "react";
import { ArcherContainer, ArcherElement } from "react-archer";

const Node = forwardRef<HTMLAnchorElement, { id: string; className?: string }>(
  (props, ref) => {
    const { id, className = "" } = props;
    const [open, setOpen] = useState(false);

    const arrowRef = useRef(null);

    const { refs, floatingStyles, context } = useFloating({
      open,
      onOpenChange: setOpen,
      middleware: [
        offset(16),
        flip(),
        shift(),
        arrow({
          element: arrowRef,
        }),
      ],
      whileElementsMounted: autoUpdate,
      placement: "top",
      strategy: "fixed",
    });

    const hover = useHover(context, { move: false });
    const focus = useFocus(context);

    const { getReferenceProps, getFloatingProps } = useInteractions([
      hover,
      focus,
    ]);

    const mergedRef = useMergeRefs([ref, refs.setReference]);

    return (
      <>
        <a
          ref={mergedRef}
          {...getReferenceProps()}
          href={`https://replicate.com/${id}`}
          className={`inline-flex w-10 h-10 lg:w-12 lg:h-12 xl:w-16 xl:h-16 rounded-full ${className} bg-gradient-to-br from-pink-500 via-red-500 to-yellow-500 p-0.5`}
        >
          <div className="bg-white h-full w-full rounded-full p-[2px]">
            <img
              alt={id}
              className="w-full h-full rounded-full object-cover"
              loading="lazy"
              src={`https://github.com/${
                id === "meta" ? "facebookresearch" : id
              }.png`}
            />
          </div>
        </a>
        {open && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            className="bg-black text-white py-1 px-2 text-sm z-10"
            {...getFloatingProps()}
          >
            <FloatingArrow ref={arrowRef} context={context} />
            {id}
          </div>
        )}
      </>
    );
  },
);

const dashStyle = { strokeDasharray: "5,5", strokeWidth: 1 };

export function NetworkDiagram() {
  return (
    <>
      <div className="flex gap-4 flex-wrap justify-center md:hidden">
        {individuals.map((individual) => {
          return <Node key={individual} id={individual} />;
        })}
      </div>
      <div className="hidden md:block">
        <ArcherContainer
          className="network-diagram"
          endMarker={false}
          lineStyle="straight"
        >
          <div className="space-y-8">
            <div className="flex gap-4 lg:gap-6 xl:gap-8 justify-evenly">
              {top.map((individual, index) => {
                if (index < top.length - 1) {
                  return (
                    <ArcherElement
                      key={individual}
                      id={individual}
                      relations={[
                        {
                          targetId: top[index + 1],
                          targetAnchor: "middle",
                          sourceAnchor: "middle",
                          className: "node-top",
                          style: dashStyle,
                        },
                      ]}
                    >
                      <div className="relative">
                        <Node className="" id={individual} />
                      </div>
                    </ArcherElement>
                  );
                }
                return (
                  <ArcherElement key={individual} id={individual}>
                    <div className="relative">
                      <Node className="" id={individual} />
                    </div>
                  </ArcherElement>
                );
              })}
            </div>
            <div className="flex gap-4 lg:gap-6 xl:gap-8 justify-evenly">
              {bottom.map((individual, index) => {
                if (index < bottom.length - 1) {
                  return (
                    <ArcherElement
                      key={individual}
                      id={individual}
                      relations={[
                        {
                          targetId: bottom[index + 1],
                          targetAnchor: "middle",
                          sourceAnchor: "middle",
                          style: dashStyle,
                          className: "node-bottom",
                        },
                      ]}
                    >
                      <div className="relative">
                        <Node className="" id={individual} />
                      </div>
                    </ArcherElement>
                  );
                }
                return (
                  <ArcherElement key={individual} id={individual}>
                    <div className="relative">
                      <Node className="" id={individual} />
                    </div>
                  </ArcherElement>
                );
              })}
            </div>
          </div>
        </ArcherContainer>
      </div>
    </>
  );
}
